import Helmet from "react-helmet"
import React from "react"
import AmoTyp from "@tightrope/amotyp"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import Styles from "./css/amotype.module.scss"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"



export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    }
    `}
    </style>
    <title>Thank you for downloading</title>
    </Helmet>

    <main className={Styles.amoContainer}>
    <img className={Styles.mainLogo} src="/img/logo.png" alt="Easy Share" />
      <section className={Styles.tyTop}>
      <div>
      <h1>Thank you for installing Easy Share</h1>
      </div>
      <div>
      <div className={Styles.aCard}>
                   <h3>How to Access Easy Share</h3>
                   <ul>
                    <li>Look for the Easy Share Icon <img src="/img/ico.png" alt="Easy Share" /></li>
                    <li><span>Can't find it?</span> Click the <img src="/img/puzzle.jpg" alt="Easy Share" />icon to expand the list of your extensions</li>
                   </ul>
      </div>
      </div>
      </section>
      <section className={Styles.tyBottom}>
      <h2>Easily Share to your favorite Social Media Sites</h2>
      <div>
      <p>Share to:</p>
      <ul>
      <li>Facebook</li>
      <li>LinkedIn</li>
      <li>X (Twitter)</li>
      <li>Pocket</li>
      <li>Pinterest</li>
      </ul>
      <p>Start now by clicking the extension icon!</p>
      </div>
      <div>
        <img className={Styles.promoImage} src="/img/easyshare-ss.jpg" alt="Easy Share"></img>
      </div>
      </section>
      <FooterlinksSingle></FooterlinksSingle>
    </main>
    
    </>
  )
}
